@font-face {
    font-family: Futura_Book_font;
    src: url("../../font/futura/Futura\ Book\ font.ttf");
}

.navbar_container{
    width: 100%;
    height: auto;
}

.iconos-navbar{
    width: 100%;
    text-align: center;
    padding-top: 10px;
}
.iconos-navbar img{
    width: 200px;
}

.navbar-seccion-grid{
    height: 60px;
    width: 100%;
    text-align: center;
}
.menu-items ul{
    list-style: none;
    display: flex;
    margin: 0;
    padding: 10px;
    justify-content: center;
}
.menu-items ul li{
    margin: 10px;
    position: relative;
    display: flex;
    align-items: center;
    width: auto;
    margin-left: 20px;
    margin-top: 0px;
}
.menu-items ul li a{
    text-decoration: none;
    color: #898989;
    font-size: 20px;
    font-family: Futura_Book_font;
}
.menu-items ul li a:hover{
    font-weight: bold;
}



.menu-cel{
display: none !important;
}
.menu-cel{
    position: absolute;
    right: 0;
    top: 0;
    color: black;
    font-size: 40px !important;
    margin-right: 20px;
    margin-top: 10px;
}
.menu-cel:hover{
    cursor: pointer;
}
.menu-flotante-cel-hi{
    position: absolute;
    width: 100%;
    height: 100vh;
    background-color: white;
    top: 0;
    left: 0;
    background-size: cover;
    z-index: 50 !important;
  }
  .logo-curiel-menu-cel{
    width: 200px;
    margin-top: 50px;
    margin-bottom: 0px;
  }
  .menu-flotante-cel-hi .menu-flotante-items{
    width: 80%;
    margin: 0 auto;
    margin-top: 40px;
    padding-left: 0px;
  }
  .menu-flotante-cel-hi .menu-flotante-items li{
    font-size: 30px;
    list-style: none;
    height: 45px;
    padding-top: 10px;
    padding-bottom: 10px;
    z-index: 10;
    position: relative;
    width: 100%;
    text-align: center;
}

    .menu-flotante-cel-hi .menu-flotante-items li:hover{
        border: 1px solid white;
    }

  .menu-flotante-cel-hi .menu-flotante-items li a{
    text-decoration: none;
    color: #555555;
    font-size: 25px;
    font-family: Futura_Book_font;
   }
    
   .menu-ingles-celular{
        color: #555555;
        text-align: center;
        width: 80%;
        padding-left: 15px;
        border: none !important;
        font-family: Futura_Book_font;
    }
    .menu-ingles-celular ul li{
        padding-top: 15px;
        padding-left: 15px;    
        font-size: 25px !important;    
    }

@media only screen and (max-width: 720px){
    .menu-items ul{
        display: none;
    }
    .menu-cel{
        display: block !important;
    }
}


@media only screen and (max-width: 480px) {
    .menu-items ul{
        display: none;
    }
    
    .menu-cel{
        display: block !important;
    }
    .menu-logo img{
        width: 300px;
        margin-top: 5px;
    }
    
  }
  
  
  
  