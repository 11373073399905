@font-face {
    font-family: Futura_Book_font;
    src: url("../../font/futura/Futura\ Book\ font.ttf");
}

.carrusel-nosotros{
    width: 60%;
    margin: 0 auto;
}

.cuarzo{
    background-color: white;
    height: 100%;
    overflow: hidden;
}
.grid-about{
    width: 70%;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-areas: "b b";
    margin: 0 auto;
    padding-top: 20px;
}
.about-img img{
    width: 100%;
    height: 450px;
    grid-area: a;
}
.about-p{
    width: 90%;
    margin: 0 auto;
    grid-area: b;
}
.about-p p{
    font-size: 20px;
    margin-top: 0px;
    color: rgb(106, 106, 106);
    padding-left: 20px;
    padding-right: 20px;
    font-family: Futura_Book_font;
    text-align: justify;
}
.text-mini{
    padding-top: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-bottom: 0px;
    font-size: 16px !important;
    color: #a4a4a4 !important;
    font-weight: bold;
    text-align: left !important;
}
.h2pp{
    font-size: 28px;
    padding-top: 0px;
    margin-top: 5px;
    color: rgb(42, 42, 42);
    padding-bottom: 0px;
    margin-bottom: 10px;
    padding-left: 20px;
    font-family: Futura_Book_font;
    font-weight: bold;
    text-align: center;
}
.filos{
    width: 80%;
    background: #7a7171 fixed no-repeat 50% 50%;
    background-size: cover;
    background-position-y: 40%;
    height: 150px;
    margin: 0 auto;
    margin-top: 50px;
    position: relative;
    overflow: hidden;
    background-color: white;
}

.filos .frase{
    font-size: 25px;
    text-align: center;
    color: white;
    width: 70%;
    margin: 0 auto;
    position: relative;
    z-index: 10;
    padding-top: 0px;
    color: #7a7171;
    font-family: Futura_Book_font;
}

@media only screen and (max-width: 480px) {
    
    .grid-about{
        width: 90%;
        grid-gap: 0px;
    }
    .about-p p{
        padding-left: 0px;
    }
    
    .text-mini{
        padding-bottom: 10px;
        font-size: 18px !important;
    }
    .h2pp{
        font-size: 28px;
        padding-bottom: 10px;
    }
    .filos .frase{
        font-size: 20px;
        width: 100%;
    }
    
}