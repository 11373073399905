.modal-view-image{
    width: 100%;
    height: 100%;
    text-align: center;
    position: relative;
}
.modal-view-image img{
    height: 80vh;
}

.left svg, .rigth svg{
    font-size: 50px;
    padding-top: 700%;
    color: rgb(45, 45, 45);
}
.left svg:hover, .rigth svg:hover{
    cursor: pointer;
}

.left{
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 100%;
    padding-left: 10px;
}
.rigth{
    position: absolute;
    top: 0%;
    right: 0;
    width: 40px;
    height: 100%;
    padding-right: 10px;
}

@media only screen and (max-width: 480px) {
    .modal-view-image img{
        width: 100%;
        height: 50vh;
    }
    .left svg, .rigth svg{
        color: white;
        padding-top: 400%;
    }
}