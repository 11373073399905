@font-face {
    font-family: Futura_Book_font;
    src: url("../../font/futura/Futura\ Book\ font.ttf");
}

.team-container{
    width: 100%;
    padding-top: 10px;
    padding-bottom: 50px;
}
.team-galery{
    width: 70%;
    margin: 0 auto;
}
.curiel-boss{
    width: 100%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 32% 32% 32%;
    grid-template-areas: "a c b";
    grid-gap: 10px;
}
.boss1{grid-area: a;}
.boss2{grid-area: b;}
.boss3{grid-area: c;}

.bosscarta{
    width: 100%;
    overflow: hidden;
}
.bosscarta .foto{
    width: 100%;
    padding-top: 10px;
    text-align: center;
}
.bosscarta .foto img{
    width: 90%;
}
.p-name{
    font-size: 20px;
    font-family: Futura_Book_font;
    text-align: center;
    margin-bottom: 0px;
    color: rgb(54, 54, 54);
    font-weight: bold;
}
.p-puesto{
    font-size: 18px;
    text-align: center;
    font-family: Futura_Book_font;
    font-weight: bold;
    padding-top: 0px;
    margin-top: 0px;
    color: gray;
}

.team h2{
    text-align: center;
    font-size: 24px;
    padding-top: 20px;
}
.grid-team{
    display: grid;
    grid-template-columns: 23% 24% 24% 24%;
    grid-template-areas: 
        "a b c d";
    margin: 0 auto;
    text-align: center;
    grid-gap: 10px;
}
.grid-team-2{
    display: grid;
    grid-template-columns: 33% 30% 30%;
    grid-template-areas: "a b c e f";
    margin: 0 auto;
    text-align: center;
    grid-gap: 10px;
    width: 80%;
}

.bosscarta2{
    width: 100%;
}
.bosscarta2 div{
    padding-top: 0px;
    margin-top: -15px;
    padding-bottom: 10px;
}
.bosscarta2 div p{
    font-size: 20px;
}
.team-arq-dis{
    font-size: 25px;
    font-family: Futura_Book_font;
    padding-bottom: 10px;
}


@media only screen and (max-width: 480px) {
   
    .curiel-boss{
        grid-template-areas: 
        "a a a"
        "b b b"
        "c c c";
        grid-gap: 0px;
    }
    .grid-team{
        display: grid;
        grid-template-columns: 50% 50%;
        grid-template-areas: 
            "a b";
        margin: 0 auto;
        text-align: center;
        grid-gap: 10px;
    }
    .grid-team p{
        text-align: center;
    }

    .grid-team-2{
        display: grid;
        margin: 0 auto;
        text-align: center;
        width: 100%;
        grid-gap: 10px;
        
    }
    .grid-team-2 p{
        text-align: center;
    }
}