@font-face {
    font-family: Martel-n2;
    src: url("../../../font/Barlow-Bold.ttf");
}

.Container-proyect{
    width: 100%;
}
.res-cart{
    height: 400px;
    border-radius: 5px;
    position: relative;
    overflow: hidden;
    background-size: cover;
    background-position-x: 50%;
    background-position-y: 90%;
}


.seccion-proyect{
    width: 100%;
    margin: 0 auto;
    height: auto;
    background: #fff fixed no-repeat 50% 50%;
    background-size: cover;
    position: relative;
    overflow: hidden;
}

.residencial-grid1{
    width: 80%;
    display: grid;
    grid-auto-columns: 33% 33% 33%;
    grid-template-areas: 
    "a a b";
    grid-gap: 10px;
    margin: 0 auto;
    position: relative;
    z-index: 10;
    padding-bottom: 20px;
}
.residencial-grid2{
    width: 80%;
    display: grid;
    grid-auto-columns: 33% 33% 33%;
    grid-template-areas: 
    "a b c";
    grid-gap: 10px;
    margin: 0 auto;
    position: relative;
    z-index: 10;
    padding-bottom: 20px;
}
.residencial-grid3{
    width: 80%;
    display: grid;
    grid-auto-columns: 33% 33% 33%;
    grid-template-areas: 
    "a b b";    
    grid-gap: 10px;
    margin: 0 auto;
    position: relative;
    z-index: 10;
    padding-bottom: 20px;
}
.res-cart:hover{
    cursor: pointer;
}
.res-cart div{
    position: absolute;
    bottom: 0;
    width: 100%;
}
.res-cart:hover div{
    background-color: rgba(0, 0, 0, 0.626);
    position: absolute;
    bottom: 0;
    width: 100%;
    animation: fadeInUp 0.3s ease-in-out forwards;
}
.res-cart:hover div p{
    display: block;
}

.grupoa-1{grid-area: a;}
.grupoa-2{grid-area: b;}
.grupob-1{grid-area: a;}
.grupob-2{grid-area: b;}
.grupob-3{grid-area: c;}
.grupoc-1{grid-area: a;}
.grupoc-2{grid-area: b;}



@keyframes fadeInUp {
    0% {
        transform: translateY(100%); /* Comienza desde abajo */
        opacity: 0;
    }
    100% {
        transform: translateY(0); /* Termina en su posición original */
        opacity: 1;
        display: block; /* Muestra el texto */
    }
}


.res-cart div p{
    color: white;
    font-size: 20px;
    text-align: center;
    display: none;
}
.ptitle{
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 5px;
}
.ptext{
    padding-top: 0px;
    margin-top: 0px;
}

@media only screen and (max-width: 480px) {
    .residencial-grid{
        width: 90%;   
        grid-auto-columns: 23% 23% 23% 23%;
        grid-template-areas: 
        "a a a a"
        "b b b b"
        "c c c c"
        "d d d d";
        grid-gap: 10px;
    }
}
