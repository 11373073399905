#scrollToTopButton {
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: none;
    z-index: 300;
    /* Otros estilos de diseño que desees agregar */
    animation: aparecer 1s;
  }
  
  #scrollToTopButton.active{
      animation: desaparecer 1s;
  }
  
  @keyframes aparecer {
      0% {
        opacity: 0;
      }
      50% {
        opacity: 0.5; 
      }
      100% {
        opacity: 1;
      }
    }
  
  
    @keyframes desaparecer {
      0% {
        opacity: 1;
      }
      50% {
        opacity: 0.5; 
      }
      100% {
        opacity: 0;
      }
    }
  
  
  
  .button-up{
      border: none;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background-color: rgba(250, 178, 35, 0.475);
  }
  .button-up:hover{
      cursor: pointer;
      
  }