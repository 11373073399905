@font-face {
    font-family: Martel;
    src: url("../../font/Barlow-Italic.ttf");
}

.hero-slider-overlay {
    background-color: rgba(0, 0, 0, 0.042);
}
.container-text{
    width: 70%;
    text-align: center;
    padding-top: 20px;
    margin: 0 auto;
}
.text-curiel-slogan{
    font-size: 40px;
    color: white;
    font-family: Martel;
}
.logo-ca{
    width: 200px;
    padding-top: 30px;
}

.pestaña-des{
    position: absolute;
    width: 30px;
    top: 0;
    right: 0;
    height: 20px;
    z-index: 10;
    margin-right: 40px;
}
.pestaña-des svg{
    color: white;
    font-size: 40px !important;
    padding-top: 7px;
}
.pestaña-des:hover{
    cursor: pointer;
}

.container-slider{
    height: auto;
    margin-bottom: -20px;
}
.hero-slider-previous-button svg{
    width: 50px !important;
}
.hero-slider-previous-button:hover svg{
    width: 50px !important;
}

.hero-slider-next-button svg{
    width: 50px !important;
}

.index-module_Horizontal__8-P70.index-module_Previous__TaJVT .index-module_Container__mtjRh{
    background: none !important;
}
.index-module_Horizontal__8-P70.index-module_Next__sFCHR .index-module_Container__mtjRh{
    background: none !important;
}

.menu {
    position: fixed;
    top: 0;
    right: -300px;
    width: 300px;
    height: 100%;
    background-color: white;
    color: #fff;
    transition: right 0.3s;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
  
  .menu.visible {
    right: 0;
    z-index: 10;
  }

  .slide3{
    background-size: cover;
    background-position-y: 100% !important;
}



@media only screen and (max-width: 480px) {

    .container-text{
        display: block;
        padding-top: 50px;
        width: 90%;
        margin: 0 auto;
        text-align: center;
    }
    .text-curiel-slogan{
        font-size: 30px;
        color: white;
        font-family: Martel;
        text-align: center;
    }
    .container-slider{
        height: auto;
        margin-bottom: 0px;
        overflow: hidden;
    }


}