@font-face {
    font-family: Futura_Book_font;
    src: url("../../font/futura/Futura\ Book\ font.ttf");
}

.menu-lateral-container{
    width: 100%;
    margin: 0 auto;
    text-align: center;
    font-family: Futura_Book_font;
}
.menu-lateral-container img{
    width: 40px;
}
.menu-lateral-container .logohor{
    width: 60%;
    padding-top: 10px;
}
.items {
    width: 90%;
    margin: 0 auto;
}
.items p{
    font-size: 18px;
    color: rgb(142, 142, 142);
    text-align: left;
    padding-bottom: 20px;
}
.items p:hover{
    cursor: pointer;
    font-weight: bold;
}

.redes-sociales{
    text-align: left;
    width: 90%;
    margin: 0 auto;
    display: flex;
}

.redes-sociales p{
    text-align: left;
    margin-right: 15px;
}
.redes-sociales svg{
    color: rgb(142, 142, 142);
    text-align: left;
    font-size: 30px;
    padding-bottom: 10px;
}
.redes-sociales svg:hover{
    cursor: pointer;
    color: rgb(43, 42, 42);
}