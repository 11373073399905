.contact-container {
    background: #fff fixed no-repeat 50% 50%;
    background-size: cover;
    height: auto;
    margin-bottom: 10px;
    padding-bottom: 20px;
    position: relative;
    width: 100%;
}
.capa-contact {
    height: 100%;
    position: absolute;
    width: 100%;
}
.contact-grid {
    display: grid;
    grid-template-areas:
        "a a"
        "b b";
    grid-template-columns: 50% 50%;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    width: 70%;
    z-index: 10;
}
.formulario {
    margin: 0 auto;
    padding-bottom: 20px;
    text-align: center;
    width: 90%;
}
.formulario {
    background-size: cover;
    grid-area: a;
}
.formulario h2 {
    color: #444;
    font-family: Futura_Book_font;
    font-size: 35px;
    font-weight: 700;
    margin-bottom: 15px;
    margin-top: 0;
    text-align: center;
}
.formulario input {
    background-color: hsla(0, 0%, 100%, .711);
    font-size: 20px;
    height: 40px;
    margin-bottom: 20px;
}

.formulario input, .formulario textarea {
    border: 1px solid gray;
    border-radius: 5px;
    color: #2f2f2f;
    font-family: Futura_Book_font;
    padding: 5px 5px 5px 10px;
    width: 80%;
}
.formulario textarea {
    background-color: hsla(0, 0%, 100%, .736);
    font-size: 22px;
    height: 100px;
}
.input-captcha{
    margin: 20px auto;
    text-align: center !important;
    width: 80%;   
}

.button-enviar {
    margin: 0 auto;
    width: 80%;
}
.mapadir {
    width: 90% !important;
}
.mapadir {
    grid-area: b;
    margin: 0 auto;
    width: 70%;
}
.button-enviar button {
    background-color: #444;
    border: none;
    border-radius: 10px;
    color: #fff;
    font-family: Futura_Book_font;
    font-size: 20px;
    height: 50px;
    margin-top: 10px;
    width: 75%;
    cursor: pointer;
}
.mapadir h2 {
    color: #444;
    font-family: Futura_Book_font;
    font-size: 35px;
    font-weight: 700;
    margin-bottom: 0;
    text-align: center;
}

.dir-grid-contac {
    display: grid;
    font-family: Futura_Book_font;
    grid-template-areas: "a b c";
    grid-template-columns: 48% 4% 48%;
    height: 70vh;
    margin: 0 auto;
    text-align: justify;
    width: 100%;
}
.dir-map1 {
    grid-area: a;
}
.dirmap {
    color: #000;
    font-size: 18px;
    margin-bottom: 0;
    padding-bottom: 0;
}
.mapadir iframe {
    height: 70%;
    margin-top: 20px;
    width: 100%;
}
.line, .line div {
    text-align: center;
}
.line {
    grid-area: b;
    height: 90%;
    margin: 20px auto 0;
    width: 100%;
}
.dir-map2 {
    grid-area: c;
}


@media only screen and (max-width: 480px){
    .dir-grid-contac {
        grid-template-areas: "a a a""b b b""c c c";
        grid-template-columns: 48% 4% 48%;
        height: auto;
    }   
}