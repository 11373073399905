.Filtro{
    width: 80%;
    margin: 0 auto;
    text-align: center;
    margin-top: 0px;
    padding-top: 0px;
    position: relative;
    height: 25px;
    display: none !important;
}
.vista{
    position: absolute;
    top: 0;
    right: 0;
}
.vista svg{
    font-size: 30px;
}

.options-filtro{
    width: auto;
    margin: 0 auto;
    display: flex;
}
.options-filtro p{
    font-size: 18px;
    color: rgb(72, 72, 72);
    margin-right: 15px;
    padding-top: 0px;
    margin-top: 0px;
}
.opbb:hover{
    cursor: pointer;
}
.opbb.select{
    font-weight: bold;
}
.Container-proyect{
    margin-bottom: 20px;
    position: relative;
}

