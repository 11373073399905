@font-face {
    font-family: Futura_Book_font;
    src: url("../../font/futura/Futura\ Book\ font.ttf");
}

.seccion-container{
    height: auto;
    padding-top: 50px;
    background: #fff fixed no-repeat 50% 50%;
    background-size: cover;
    position: relative;
    overflow: hidden;
    padding-bottom: 20px;
    margin-bottom: 40px !important;
    margin-top: 10px;
    width: 80%;
    margin: 0 auto;
}
#seccion-container{
    background-image: url(../../images/imag1.jpg);
} 
.capa{
    width: 100%;
    height: 100%;
    top: 0;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.668);
} 

.seccion-cont-grid{
    display: grid;
    grid-template-columns: 30% 30% 30%;
    grid-template-areas: "a b c";
    width: 90%;
    margin: 0 auto;
    position: relative;
    z-index: 20;
    grid-gap: 5%;
}
.secg1{
    grid-area: a;
    color: white;
}
.secg2{
    grid-area: b;
    color: white;
}
.secg3{
    grid-area: c;
    color: white;
}

.seccinfo{
    text-align: center;
}
.seccinfo img{
    width: 80px;
}
.seccinfo h2{
    font-size: 20px;
    text-align: center;
    padding-top: 0px;
    margin-top: 0px;
    font-family: Futura_Book_font;
}
.seccinfo p{
    font-size: 20px;
    text-align: justify;
    font-family: Futura_Book_font;
}
.titulo-text{
    width: 100%;
    margin: 0 auto;
    text-align: center;
    position: relative;
    z-index: 20;
}
.titulo-text p{
    font-size: 24px;
    padding-top: 0px;
    margin-top: 0px;
    padding-bottom: 20px;
    color: white;
    font-family: Futura_Book_font;
}
.p-name-normal{
    font-size: 20px;
    text-align: center;
}



@media only screen and (max-width: 480px) {
    .seccion-cont-grid{
        grid-template-areas: 
        "a a a"
        "b b b"
        "c c c";
        padding-bottom: 100px;
    }
    .titulo-text p{
        font-size: 20px;
    }

}