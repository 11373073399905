@font-face {
    font-family: Futura_Book_font;
    src: url("../../font/futura/Futura\ Book\ font.ttf");
}
.footer-container{
    width: 100%;
    height: auto;
    text-align: center;
    font-family: Futura_Book_font;
    color: rgb(87, 87, 87);
}
.footer-container .column-footer{
    width: 80%;
    margin: 0 auto;
}
.col2 img{
    width: 200px !important;
}
.cont-redes{
    width: 20%;
    margin: 0 auto;
}

.cont-iconos{
    display: grid;
    grid-template-columns: 23% 23% 23% 23%;
    grid-template-areas: " a b c d";
    width: 80%;
    margin: 0 auto;
    grid-gap: 5%;
    text-align: left;
    padding-bottom: 10px;
}
.gcf1, .gcf2, .gcf3, .gcf4{
    border-right: 1px solid rgb(87, 87, 87);
}
.grid-colum-footer div p{
    margin-top: 10px;
    margin-bottom: 0px;
}   

.cont-iconos .iconos{
    color: rgb(87, 87, 87);
    width: 40px;
}
.cont-iconos .iconos svg{
    font-size: 30px;
}
.cont-iconos .iconos svg:hover{
    cursor: pointer;
    color: black;
}

.grid-colum-footer{
    display: grid;
    grid-template-columns: 20% 20% 20% 20% 20%;
    grid-template-areas: " a b c d e";
    width: 70%;
    margin: 0 auto;
}
.grid-colum-footer div p{
    font-size: 20px;
}

.grid-colum-footer div p:hover{
    cursor: pointer;
    font-weight: bold;
    text-decoration: underline;
    color: gray;
}


@media only screen and (max-width: 480px) {
    .grid-colum-footer{
        display: none;
    }
    .cont-iconos{
        width: 100%;
    }
    .cont-redes{
        width: 50%;
    }
}
