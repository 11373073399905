
.container_view_project{
    width: 100%;
}
.slider-view-project{
    width: 80%;
    margin: 0 auto;
    text-align: center;
}
.slider-view-project img{
    width: 90%;
}
.gallery_project{
    width: 72%;
    margin: 0 auto;
}
.text-gll{
    width: 100%;
    text-align: center;
    height: 50px;
}
.text-gll h2{
    font-size: 30px;
    margin-bottom: 0px;
}
.text-gll p{
    margin-top: 0px;
    font-size: 20px;
    font-style: italic;
    color: rgb(83, 83, 83);
}
.grid-gallery{
    width: 100%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 49% 49%;
    grid-template-areas: 
    "a b";
    grid-gap: 2%;
    margin-bottom: 20px;
}
.grid-gallery .gridgall{
    width: 100%;
    height: 400px;
    background-position: 0% 100%;
}
.grid-gallery .gridgall:hover{
    cursor: pointer;
}

@media only screen and (max-width: 480px){
   
.grid-gallery{
    grid-template-areas: 
    "a a"
    "b b"
    "c c"
    "d d";
}
}

.modalimage{
    min-width: 80% !important;
    width: 80% !important;
}